import Component from 'vue-class-component'
import Dashboard from '../Dashboard'
import { generateFilters } from './filters'
import { generateFilters as generalFilters } from '../supervisor/filters'

@Component
export default class StaffDashboard extends Dashboard {
  beforeCreate () {
    this.filterGenerator = generalFilters
    this.specificFilterGenerator = generateFilters
  }
}
