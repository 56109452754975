
  import { channelColors } from '@/utils/channelColors'
  import { Component } from 'vue-property-decorator'
  import StaffDashboard from './StaffDashboard'

@Component
  export default class StaffLeads extends StaffDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {}
    }

    colorChannels (leads) {
      for (let i = 0; i < leads.length; i++) {
        if (leads[i].channel) {
          leads[i].channel.color = channelColors[leads?.[i]?.channel?.name] || '#737373'
        } else {
          leads[i].channel = undefined
        }
      }
      return leads
    }

  // Getters

  // Watchers
  }
